.container {
  display: flex;
  margin: 1rem 0;
  align-items: center;
  justify-content: center;

  svg {
    fill: #48494d;
    stroke: #48494d;
  }
}
