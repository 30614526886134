.container {
  width: 400px;
  background-color: #ffffff;
  margin: 2rem auto 0;
  border-radius: 0.5rem;
  border: 1px solid #eaebef;
  color: #303549;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  .signInText {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 24px;
    font-weight: 600;
    color: #2c3e50;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    margin: 2rem 1rem 0;
    width: auto;
  }
}
