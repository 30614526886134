.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & button {
    margin: 20px 0;
  }

  & p {
    font-size: var(--font-size-10xl);
    margin: 10px 0;
  }
}
