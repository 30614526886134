.container {
  display: flex;
  height: 7rem;
  background-color: #2c3e50;

  .content {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    padding: 0 1rem;

    .logo {
      fill: #ffffff;
    }

    .containerTop {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
    }

    .containerData {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 1.75rem;

      .moduleText {
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
}
