:root {
  /* ##### Colors ####### */
  /* Main Colors */
  --color-primary-dark: #ff738b;
  --color-primary: #ff8b9f;
  --color-primary-light: #ffbbc6;
  --color-primary-with-opacity: rgba(255, 139, 159, 0.15);

  /* Neutral colors */
  --color-secondary-dark: #72cdca;
  --color-secondary: #5ddeda;
  --color-secondary-light: #a7f1ef;

  --color-blackish-dark: #151515;
  --color-blackish: #2b2b2b;
  --color-blackish-light: #3d3d3d;

  --color-grayish-dark: #7d7d7d;
  --color-grayish: #aaacad;
  --color-grayish-light: #c2c2c2;

  --color-neutral-dark: #f0f0f0;
  --color-neutral: #f8f8f8;
  --color-neutral-light: #ffffff;

  --color-success-dark: #5c9a52;
  --color-success: #2fa968;
  --color-success-light: #6ae5a4;

  --color-danger-dark: #c22929;
  --color-danger: #ff3636;
  --color-danger-light: #ff6868;

  /* ##### Borders ####### */
  --border-radius-small: 1px;
  --border-radius-default: 2px;
  --border-radius-input: 4px;

  /* Border Colors */
  --border-primary-dark: 1px solid var(--color-primary-dark);
  --border-primary: 1px solid var(--color-primary);
  --border-primary-light: 1px solid var(--color-primary-light);

  --border-secondary-dark: 1px solid var(--color-secondary-dark);
  --border-secondary: 1px solid var(--color-secondary);
  --border-secondary-light: 1px solid var(--color-secondary-light);

  /* Border Neutral */
  --border-blackish-dark: 1px solid var(--color-blackish-dark);
  --border-blackish: 1px solid var(--color-blackish);
  --border-blackish-light: 1px solid var(--color-blackish-light);

  --border-grayish-dark: 1px solid var(--color-grayish-dark);
  --border-grayish: 1px solid var(--color-grayish);
  --border-grayish-light: 1px solid var(--color-grayish-light);

  --border-neutral-dark: 1px solid var(--color-neutral-dark);
  --border-neutral: 1px solid var(--color-neutral);
  --border-neutral-light: 1px solid var(--color-neutral-light);

  /* Border Alert */
  --border-danger-secondary-dark: 1px solid var(--color-danger-dark);
  --border-danger-secondary: 1px solid var(--color-danger);
  --border-danger-secondary-light: 1px solid var(--color-danger-light);

  /**  ##### Typography ####### */
  --font-family: 'Lato', sans-serif;
  --font-style: normal;

  --font-size-12xl: 4rem; /** 64px **/
  --font-size-10xl: 2.75rem; /** 44px **/
  --font-size-9xl: 2.5rem; /** 40px **/
  --font-size-7xl: 2rem; /** 32px */
  --font-size-4xl: 1.5rem; /**  24px */
  --font-size-3xl: 1.375rem; /**  22px */
  --font-size-2xl: 1.25rem; /**  20px */
  --font-size-xl: 1.215rem; /**  18px */
  --font-size-lg: 1rem; /**  16px */
  --font-size-normal: 0.875rem; /** 14px */
  --font-size-xsm: 0.75rem; /**  12px */

  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;

  --line-height-default: 1.215rem; /**  18px */

  --line-height-xs: 0.75rem; /**  12px */
  --line-height-sm: 1rem; /**  16px */
  --line-height-lg: 1.25rem; /**  20px */
  --line-height-xl: 1.375rem; /**  22px */
  --line-height-2xl: 1.5rem; /**  24px */
  --line-height-5xl: 1.875rem; /**  30px */
  --line-height-7xl: 2.25rem; /**  36px */
  --line-height-10xl: 2.625rem; /**  42px */
  --line-height-12xl: 3rem; /**  48px */
  --line-height-14xl: 3.375rem; /**  54px */

  --letter-space-default: 0.777778px; /**  18px */

  --letter-space-xs: 0.3px; /**  12px */
  --letter-space-sm: 0.75px; /**  14px */
  --letter-space-lg: 1px; /**  20px */

  --default-font-color: var(--color-grayish-dark);
}
