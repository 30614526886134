.button {
  background: #ffffff;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.primary {
  height: 2.5rem;
  border: 1px solid #ffffff;
  border-radius: 0.35rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #2c3e50;
  font-size: 14px;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }
}

.secondary {
  height: 2.5rem;
  border: 1px solid #eaebef;
  border-radius: 0.35rem;
  font-weight: 500;
  font-size: 14px;
}

.tertiary {
  height: 3rem;
  width: 100%;
  background-color: #2c3e50;
  color: #ffffff;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 14px;
  border: 0px;
  justify-content: center;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }
}

.icon {
  height: 3rem;
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #c1c1c1;
  justify-content: center;
  margin-right: 0.25rem;

  svg {
    fill: #2c3e50;
  }
}

.login {
  height: 3rem;
  border: 1px solid #ffffff;
  border-radius: 0.35rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #2c3e50;
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }
}

.login:disabled {
  background-color: rgba(43, 61, 79, 0.2);
}

.warning {
  height: 2.5rem;
  border: 1px solid #ffffff;
  border-radius: 0.35rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #f39c12;
  font-size: 14px;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
  }
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-grayish);
  margin-left: 1rem;
}

.row {
  display: flex;
  gap: 1rem;
}
