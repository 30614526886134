.paragraph {
  font-size: var(--font-size-lg);
  color: var(--color-grayish-dark);
  font-weight: var(--font-weight-400);
}

.semiBold {
  font-weight: var(--font-weight-600);
}

.light {
  font-weight: var(--font-weight-300);
}
