.container {
  display: flex;
  flex-direction: column;

  .input {
    display: flex;
    width: 100%;
  }

  .title {
    font-size: 13px;
    color: #2c3e50;
    font-weight: 500;
  }
}
