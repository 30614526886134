.header {
  background-color: #2c3e50;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #51535e;
  color: white;

  .logo {
    height: 40vmin;
    pointer-events: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 81px);
}
