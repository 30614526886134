.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  margin: 8px 0;
}

.helperText {
  margin: 8px 0;
  font-size: var(--font-size-sm);
}
