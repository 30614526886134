.caption {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-500);
}

.bold {
  font-weight: var(--font-weight-900);
}

.fs-4xl {
  font-size: var(--font-size-4xl);
  line-height: var(--line-height-7xl);
}

.fs-xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-7xl);
}

.fs-normal {
  font-size: var(--font-size-normal);
  line-height: var(--line-height-5xl);
}
