.link {
  font-size: var(--font-size-lg);
  padding: 8px 16px;
  color: var(--color-grayish-dark);
  cursor: pointer;
  font-weight: var(--font-weight-400);
}

.none {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.fs-xsm {
  font-size: var(--font-size-xsm);
}

.fs-normal {
  font-size: var(--font-size-normal);
}

.fs-lg {
  font-size: var(--font-size-lg);
}
