.container {
  background-color: #2c3e50;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #51535e;
  color: white;
  position: relative;
  left: 0;
  bottom: 0;
  margin-top: 2rem;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 14px;
}
