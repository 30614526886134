.container {
  max-width: 1300px;
  width: 100%;
  background-color: #ffffff;
  margin: -3rem auto 0;
  border-radius: 0.5rem;
  border: 1px solid #eaebef;
  color: #303549;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table thead th {
    border-bottom: 1px solid #eaebef;
    height: 1.85rem;
    padding: 1rem 1.5rem 0.15rem;
    text-align: left;
    font-weight: 500;
    line-height: 1rem;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #62677b;
  }

  @media only screen and (max-width: 768px) {
    .thPhone {
      display: none;
    }
  }
}
