table td {
  border-bottom: 1px solid #eaebef;
  padding: 0.5rem;
  height: 3.5rem;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: row;
  cursor: pointer;
}

.alignEnd {
  justify-content: flex-end;
  display: flex;
}

.arrow {
  fill: #222222;
}

.tdDetails {
  width: 2rem;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.expandable {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  padding: 1rem 0;

  .containerDatails {
    margin: 1rem 0;
    cursor: auto;

    .label {
      font-size: 12px;
    }

    .value {
      font-size: 14px;
      margin: 0 0 0.75rem;
      color: #303549;
    }
  }
}

.detailPhone {
  display: none;
}

@media only screen and (max-width: 768px) {
  .tdPhone {
    display: none;
  }

  .detailPhone {
    display: block;
  }
}
