.container {
  display: flex;
  flex-direction: column;

  .account {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2c3e50;
    box-shadow: 0 0 10px rgba(27, 38, 49, 0.5);
    border-radius: 0.25rem;
    padding: 0.75rem 1.25rem;
    margin-top: 1rem;

    .userText {
      color: #ffffff;
      font-weight: 600;
      line-height: 1rem;
      font-size: 14px;
      margin: 0 1rem 0 0.5rem;
    }

    .logout {
      color: #ffffff;
      font-weight: 500;
      line-height: 1rem;
      font-size: 14px;
      margin: 0 0.25rem 0 2rem;
      cursor: pointer;
    }
  }
}
