.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #253444;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  box-shadow: inset 0 3px 6px -3px #151d25;
  min-width: 12rem;

  .balanceText {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    margin: 0;
  }

  svg{
    margin-right: 1rem;
  }
}
