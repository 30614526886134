.container {
  color: #303549;

  .darkBG {
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 98;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }

  .modal {
    min-width: 50vh;
    max-height: 95vh;
    overflow-y: auto;
    background: #ffffff;
    z-index: 10;
    position: relative !important;
    border-radius: 0.25rem;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 1rem;

    .header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;

      .title {
        font-weight: 600;
        line-height: 1.5rem;
        font-size: 16px;
        color: #303549;
      }

      svg {
        cursor: pointer;
        fill: #303549;
      }
    }
  }
}
