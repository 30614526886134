.container {
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 14px;
  gap: 0.75rem;
  right: 0;
  position: absolute;
  margin-right: 2rem;

  img {
    cursor: pointer;
  }
}
